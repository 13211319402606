<template>
  <div id="fellow-travelers-edit">
    <p class="title">{{ $t('settings.fellowtravelers.titleEdit') }}</p>
    <md-field>
      <label>{{ $t('settings.profile.firstName') }}</label>
      <md-input v-model="profile.firstName"></md-input>
    </md-field>

    <md-field>
      <label>{{ $t('settings.profile.lastName') }}</label>
      <md-input v-model="profile.lastName"></md-input>
    </md-field>

    <md-field>
      <label>{{ $t('settings.profile.gender') }}</label>
      <md-select v-model="profile.gender">
        <md-option value="MALE">{{ $t('settings.profile.male') }}</md-option>
        <md-option value="FEMALE">{{
          $t('settings.profile.female')
        }}</md-option>
      </md-select>
    </md-field>

    <md-datepicker
      v-model="profile.birthDate"
      :md-disabled-dates="disabledDates"
      ref="datepicker"
      @click.native="$refs.datepicker.showDialog = true"
    >
      <label>{{ $t('settings.profile.birthDate') }}</label>
    </md-datepicker>

    <md-field>
      <label>{{ $t('settings.fellowtravelers.documentType') }}</label>
      <md-select v-model="profile.personIdType" readonly disabled>
        <md-option value="Passport">{{
          $t('settings.fellowtravelers.documentTypes.passport')
        }}</md-option>
      </md-select>
    </md-field>

    <md-field>
      <label>{{ $t('settings.fellowtravelers.documentNumber') }}</label>
      <md-input v-model="profile.personId" readonly disabled></md-input>
    </md-field>

    <!-- <p class="note">{{$t('settings.fellowtravelers.documentDescription')}}</p> -->

    <b-alert v-model="error" variant="danger" dismissible>
      {{ $t('settings.fillAllField') }}
    </b-alert>

    <div class="cancel" @click="$router.push('/settings/fellowtravelers')">
      {{ $t('settings.cancel') }}
    </div>
    <div class="save" @click="save">{{ $t('settings.save') }}</div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      profile: {},
      wantDelete: false,
      error: false,
    }
  },
  mounted() {
    this.$api.get(`user/guests`).then(res => {
      res.data.data.forEach(guest => {
        if (guest.guestId == this.id) this.profile = guest
      })
    })
  },
  methods: {
    save() {
      let mandatory = ['firstName', 'lastName', 'gender', 'birthDate']
      let allFilled = true
      mandatory.forEach(field => {
        if (
          typeof this.profile[field] == 'undefined' ||
          this.profile[field] == ''
        ) {
          this.error = true
          allFilled = false
        }
      })
      if (!allFilled) return

      this.$api
        .patch(`user/guests/${this.id}`, {
          firstName: this.profile.firstName.trim(),
          lastName: this.profile.lastName.trim(),
          gender: this.profile.gender,
          birthDate: new Date(this.profile.birthDate).normalize(),
        })
        .then(() => {
          window.location.reload()
        })
    },
    disabledDates(date) {
      return date > new Date()
    },
  },
}
</script>

<style lang="scss">
#fellow-travelers-edit {
  margin: 0 auto;
  max-width: 500px;
  font-family: Roboto;
  padding: 0 15px 0 15px;

  .title {
    color: #47525e;
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 30px;
  }

  .note {
    color: #7a7a7a;
    font-size: 15px;
  }

  .save {
    background-color: #47525e;
    float: right;
    border-radius: 5px;
    height: 35px;
    width: 121px;
    line-height: 35px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s all;
    &:hover {
      filter: brightness(1.2);
    }
  }
  .cancel {
    border: #47525e 1px solid;
    float: right;
    border-radius: 5px;
    height: 35px;
    width: 121px;
    line-height: 35px;
    color: #47525e;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s all;
    margin-left: 25px;
    &:hover {
      filter: brightness(1.2);
    }
  }
}
</style>
